import './projects.scss'
import React, { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import Navbuttons from './components/navbuttons/Navbuttons'
import Loader from 'react-loaders'
import AnimatedLetters from '../animated-letters/AnimatedLetters'

function Portfolio() {
  const projects = ['P', 'r', 'o', 'j', 'e', 'c', 't', 's']
  const [letterClass, setLetterClass] = useState('text-animate')

  useEffect(() => {
    return () => {
      setTimeout(() => {
        setLetterClass('use-effect-change-class-projects')
      }, 3000)
    }
  }, [])

  return (
    <>
      <div className="container projects-page">
        <div className="text-zone">
          <h1>
            <AnimatedLetters
              idx={15}
              strArray={projects}
              letterClass={letterClass}
            />
          </h1>
        </div>
        <Navbuttons />
        <Outlet />
      </div>
      <Loader type="ball-clip-rotate-multiple" />
    </>
  )
}

export default Portfolio
