import './App.scss'
import { Routes, Route } from 'react-router-dom'
import MainLayout from './components/layouts/MainLayout'
import Home from './components/home/Home'
import About from './components/about/About'
import Contact from './components/contact/Contact'
import Projects from './components/projects/Projects'
import PythonDjango from './components/projects/components/python-django/PythonDjango'
import JavascriptReact from './components/projects/components/javascript-react/JavascriptReact'
import DatascienceMachinelearning from './components/projects/components/datascience-machinelearning/DatascienceMachinelearning'

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="projects" element={<Projects />}>
            <Route index element={<PythonDjango />} />
            <Route path="javascript-react" element={<JavascriptReact />} />
            <Route
              path="datascience-machinelearning"
              element={<DatascienceMachinelearning />}
            />
          </Route>
          <Route path="contact" element={<Contact />} />
        </Route>
      </Routes>
    </>
  )
}

export default App
