import './MainSidebar.scss'
import { Link, NavLink } from 'react-router-dom'
import React, { useState } from 'react'
import LogoS from '../../assets/images/logo-s.png'
import LogoSubtitle from '../../assets/images/logo_sub.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEnvelope,
  faHome,
  faUser,
  faBriefcase,
  faBars,
  faClose,
} from '@fortawesome/free-solid-svg-icons'
import {
  faLinkedinIn,
  faGithub,
  faSkype,
} from '@fortawesome/free-brands-svg-icons'

const MainSidebar = () => {
  const [showNav, setshowNav] = useState(false)
  console.log(setshowNav)
  return (
    <>
      <div className="nav-bar">
        <Link className="logo" to="/">
          <img src={LogoS} alt="logo" />
          <img src={LogoSubtitle} alt="logo" className="sub-logo" />
        </Link>

        <nav className={showNav ? 'mobile-show' : ''}>
          <NavLink
            exact="true"
            activeclassname="active"
            to="/"
            className="home-link"
            onClick={() => setshowNav(false)}
          >
            <FontAwesomeIcon icon={faHome} color="#4d4d4e" />
          </NavLink>
          <NavLink
            exact="true"
            activeclassname="active"
            className="about-link"
            to="/about"
            onClick={() => setshowNav(false)}
          >
            <FontAwesomeIcon icon={faUser} color="#4d4d4e" />
          </NavLink>
          <NavLink
            exact="true"
            activeclassname="active"
            className="projects-link"
            to="/projects"
            onClick={() => setshowNav(false)}
          >
            <FontAwesomeIcon icon={faBriefcase} color="#4d4d4e" />
          </NavLink>
          <NavLink
            exact="true"
            activeclassname="active"
            className="contact-link"
            to="/contact"
            onClick={() => setshowNav(false)}
          >
            <FontAwesomeIcon icon={faEnvelope} color="#4d4d4e" />
          </NavLink>
          <FontAwesomeIcon
            icon={faClose}
            color="#ffd700"
            size="3x"
            className="close-icon"
            onClick={() => setshowNav(false)}
          />
        </nav>

        <ul>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.linkedin.com/in/zmaktr/"
            >
              <FontAwesomeIcon icon={faLinkedinIn} color="#4d4d4e" />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://github.com/zmaktr"
            >
              <FontAwesomeIcon icon={faGithub} color="#4d4d4e" />
            </a>
            <a target="_blank" rel="noreferrer" href="skype:live:zaeemakhtar">
              <FontAwesomeIcon icon={faSkype} color="#4d4d4e" />
            </a>
          </li>
        </ul>
        <FontAwesomeIcon
          icon={faBars}
          color="#ffd700"
          size="3x"
          className="hamburger-icon"
          onClick={() => setshowNav(true)}
        />
      </div>
    </>
  )
}

export default MainSidebar
