import './about.scss'
import React, { useEffect, useState } from 'react'
import AnimatedLetters from '../animated-letters/AnimatedLetters'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPython,
  faCss3Alt,
  faReact,
  faJsSquare,
  faGitAlt,
  faHtml5,
} from '@fortawesome/free-brands-svg-icons'
import Loader from 'react-loaders'

const About = () => {
  const [letterClass, setLetterClass] = useState('text-animate')
  const aboutUs = ['A', 'b', 'o', 'u', 't', ' ', 'm', 'e']
  useEffect(() => {
    return () => {
      setTimeout(() => {
        setLetterClass('use-effect-change-class-about')
      }, 3000)
    }
  })
  return (
    <>
      <div className="container about-page">
        <div className="text-zone">
          <h1>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={aboutUs}
              idx={15}
            />
          </h1>
          <p>
            I'm very ambitious full-stack developer looking for a role in
            established IT company with the opportunity to work with the latest
            technologies on challenging and diverse projects.
          </p>
          <p>
            I'm quiet confident , naturally curious, and perpetually working on
            improving my skills one at a time.
          </p>
          <p>
            If I need to define myself in one sentence that would be a family
            person, father of a beautiful son and a daughter, a thinker, love
            maths, and tech-obsessed!!
          </p>
        </div>
        <div className="stage-cube-cont">
          <div className="cubespinner">
            <div className="face1">
              <FontAwesomeIcon icon={faCss3Alt} color="#34668f" />
            </div>
            <div className="face2">
              <FontAwesomeIcon icon={faHtml5} color="#28A4D9" />
            </div>
            <div className="face3">
              <FontAwesomeIcon icon={faPython} color="#28A4D9" />
            </div>
            <div className="face4">
              <FontAwesomeIcon icon={faReact} color="#5ED4F4" />
            </div>
            <div className="face5">
              <FontAwesomeIcon icon={faJsSquare} color="#EFD81D" />
            </div>
            <div className="face6">
              <FontAwesomeIcon icon={faGitAlt} color="#EC4D28" />
            </div>
          </div>
        </div>
      </div>
      <Loader type="ball-clip-rotate-multiple" />
    </>
  )
}

export default About
