import React, { useEffect, useState, useRef } from 'react'
import './contact.scss'
import Loader from 'react-loaders'
import AnimatedLetters from '../animated-letters/AnimatedLetters'
import emailjs from '@emailjs/browser'
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
import ReCAPTCHA from 'react-google-recaptcha'

const Contact = () => {
  const [emailStatus, setEmailStatus] = useState('')
  const [letterClass, setLetterClass] = useState('text-animate')
  const contactUs = ['C', 'o', 'n', 't', 'a', 'c', 't', ' ', 'm', 'e']

  const form = useRef()
  const tokenRef = useRef()

  const sendEmail = (e) => {
    e.preventDefault()
    const params = {
      'g-recaptcha-response': tokenRef.current.getValue(),
    }
    // if (verified === true) {
    emailjs
      .sendForm(
        'service_6en9g0t',
        'template_ry1785j',
        form.current,
        'ot7am4ztj31-1nyYD',
        params
      )
      .then(
        (result) => {
          setEmailStatus('Email sent success')
          console.log(result.text)
        },
        (error) => {
          console.log(error.text)
          setEmailStatus(`${error.text} happened`)
        }
      )
  }

  useEffect(() => {
    return () => {
      setTimeout(() => {
        setLetterClass('use-effect-change-class-contact')
      }, 3000)
    }
  })

  return (
    <>
      <div className="container contact-page">
        <div className="text-zone">
          <h1>
            <AnimatedLetters
              strArray={contactUs}
              idx={15}
              letterClass={letterClass}
            />
          </h1>
          <p>
            I am interested in freelance opportunities - especially on ambitious
            or large projects. Please, don't hesitate to contact me using below
            form either.
          </p>
          <div className="contact-form">
            <form ref={form} onSubmit={sendEmail}>
              <ul>
                <li className="half">
                  <input type="text" name="name" placeholder="Name" required />
                </li>
                <li className="half">
                  <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    required
                  />
                </li>
                <li>
                  <input
                    placeholder="Subject"
                    type="text"
                    name="subject"
                    required
                  />
                </li>
                <li>
                  <textarea
                    placeholder="Message"
                    name="message"
                    required
                  ></textarea>
                </li>
                <ReCAPTCHA
                  sitekey="6LfbI8UhAAAAAAvhUdMiWcCUqtJwm1rKA0vk6-dC"
                  // original => sitekey="6LfbI8UhAAAAAAvhUdMiWcCUqtJwm1rKA0vk6-dC"
                  // onChange={(value)=>{'g-recaptcha-response': value'}
                  ref={tokenRef}
                />
                <li>
                  <input type="submit" className="flat-button" value="Send" />
                </li>
              </ul>
            </form>
            <p className="email-status">{emailStatus}</p>
          </div>
        </div>
        <div className="map-canvas">
          Zaeem Akhtar,
          <br />
          Pakistan,
          <br />
          26-Q,
          <br />
          Izmir Town, Lahore
          <br />
          <a href="mailto:zmaktr@gmail.com">zmaktr@gmail.com</a>
          <span>
            <a
              href="https://calendly.com/zaeemakhtar/1-1-meeting-with-zaeem"
              target="_blank"
              rel="noreferrer"
            >
              <button className="link-meeting">Schedule a Meeting</button>
            </a>
          </span>
        </div>
        <div className="map-wrap">
          <MapContainer center={[31.4095501433259, 74.182424657672]} zoom={13}>
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <Marker position={[31.4095501433259, 74.182424657672]}>
              <Popup>Zaeem lives here, come over for a cup of coffee :)</Popup>
            </Marker>
          </MapContainer>
        </div>
      </div>
      <Loader type="ball-clip-rotate-multiple" />
    </>
  )
}

export default Contact
