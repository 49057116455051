import projects from '../../projects.json'
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub } from '@fortawesome/free-brands-svg-icons'
import { faLaptop } from '@fortawesome/free-solid-svg-icons'

function JavascriptReact() {
  const descriptionLink = (project) => {
    if (project.link !== undefined) {
      return (
        <a target="_black" href={project.link}>
          here
        </a>
      )
    }
  }
  const projectLink = (project) => {
    if (project.projecturl !== undefined) {
      return (
        <button
          onClick={() => window.open(project.projecturl)}
          className="btn-project"
        >
          View Project <FontAwesomeIcon icon={faLaptop} color="#4d4d4e" />
        </button>
      )
    }
  }
  const codeLink = (project) => {
    if (project.codeurl !== undefined) {
      return (
        <button
          onClick={() => window.open(project.codeurl)}
          className="btn-code"
        >
          Code <FontAwesomeIcon icon={faGithub} color="#4d4d4e" />
        </button>
      )
    }
  }

  return (
    <div className="projects-container">
      {projects.jsx.map((project, idx) => {
        return (
          <div className="project-box" key={idx}>
            <div className="image-wrap">
              <img
                src={project.cover}
                alt="portfolio"
                className="project-image"
              />
            </div>
            <div className="project-content">
              <h4> {project.title}</h4>
              <p>
                <span>
                  <b style={{ color: '#ffd700' }}>Description: </b>
                </span>
                {project.description}
                {descriptionLink(project)}
              </p>

              <p>
                <span>
                  <b style={{ color: '#ffd700' }}>Skills: </b>
                </span>
                {project.skills}
              </p>
              {projectLink(project)}
              {codeLink(project)}
            </div>
          </div>
        )
      })}
    </div>
  )
}
export default JavascriptReact
