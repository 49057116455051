import './MainLayout.scss'
import Background from './Allah.png'
import React from 'react'
import MainSidebar from '../sidebar/MainSidebar'
import { Outlet } from 'react-router-dom'

const MainLayout = () => {
  return (
    <div className="App">
      <MainSidebar />
      <div className="page">
        <span className="top-right">
          <img className="background-img" alt="background" src={Background} />
        </span>
        <span className="tags top-tags">&lt;body&gt;</span>
        <Outlet />
        <span className="tags bottom-tags"> &lt;/body&gt; </span>
        <br />
        <span className="tags bottom-tags-html">&lt;/html&gt;</span>
      </div>
    </div>
  )
}

export default MainLayout
