import React from 'react'
import './navbutton.scss'
import { NavLink } from 'react-router-dom'

function Navbuttons() {
  return (
    <>
      <div className="project-nav-bar">
        <div className="skill-nav-lg">
          <NavLink
            activeclassname="active"
            exact="true"
            className="python-django link"
            to="/projects"
            end
            // onClick={() => props.setSkill('project.py')}
          >
            Python / Django
          </NavLink>
          <NavLink
            activeclassname="active"
            exact="true"
            className="javascript-react link"
            to="/projects/javascript-react"
            // onClick={() => props.setSkill('project.jsx')}
          >
            Javascript / React
          </NavLink>
          <NavLink
            activeclassname="active"
            exact="true"
            className="datascience-machinelearning link"
            to="/projects/datascience-machinelearning"
            // onClick={() => props.setSkill('project.ml')}
          >
            Datascience / Machine Learning
          </NavLink>
        </div>
      </div>
    </>
  )
}

export default Navbuttons
