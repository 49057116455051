import React, { useEffect, useState } from 'react'
import LogoTitle from '../../assets/images/logo-s.png'
import { Link } from 'react-router-dom'
import './home.scss'
import AnimatedLetters from '../animated-letters/AnimatedLetters'
import Logo from './logo/Logo'
import Loader from 'react-loaders'

const Home = () => {
  const [letterClass, setLetterClass] = useState('text-animate')
  const HiArray = ['H', 'i', '!']
  const ImArray = ['I', ' ', 'a', 'm']
  const nameArray = ['a', 'e', 'e', 'm', ',']
  const jobArray = [
    'a',
    ' ',
    's',
    'o',
    'f',
    't',
    'w',
    'a',
    'r',
    'e',
    ' ',
    'd',
    'e',
    'v',
    'e',
    'l',
    'o',
    'p',
    'e',
    'r',
    '.',
  ]

  useEffect(() => {
    return () => {
      setTimeout(() => {
        setLetterClass('use-effect-change-class-home')
      }, 4000)
    }
  }, [])

  return (
    <>
      <div className="container home-page">
        <div className="text-zone">
          <h1>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={HiArray}
              idx={7}
            />
            <br />
            <AnimatedLetters
              letterClass={letterClass}
              strArray={ImArray}
              idx={11}
            />
            <img src={LogoTitle} alt="developer" />
            <AnimatedLetters
              letterClass={letterClass}
              strArray={nameArray}
              idx={15}
            />
            <br />
            <AnimatedLetters
              letterClass={letterClass}
              strArray={jobArray}
              idx={20}
            />
          </h1>
          <h2>Full Stack Developer | Python | Django | React </h2>
          <Link to="/contact" className="flat-button">
            {' '}
            CONTACT ME{' '}
          </Link>
        </div>
        <Logo />
      </div>
      <Loader type="ball-clip-rotate-multiple" />
    </>
  )
}

export default Home
